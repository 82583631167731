// Blockquote style 01
.blockquote-style-01 {
  padding: 6rem;


  @media (min-width: var(--xl)) {
    padding: 2rem;
  }

  @media (min-width: var(--md)) {
    padding: 5rem;
  }

  h6 {
    color: #fff;
    font-family: var(--alt-font);
    font-weight: 500;
    margin-bottom: 30px;
    width: 90%;
  }

  h2 {
    color: #fff;
    font-size: 20px;
    font-family: var(--alt-font);
    font-weight: 300;
    margin-bottom: 30px;
    width: 90%;
    letter-spacing: 1px;
    line-height: 30px;
  }

  i {
    display: block;
    color: #ffffff;
    font-size: 40px;
    margin-bottom: 25px;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--alt-font);
    color: #ffffff;
    opacity: 0.7;
    font-weight: 400;
  }
}

// Blockquote style 02
.blockquote-style-02 {
  padding: 0px 20px;
  border-left: 6px;
  border-color: #D36135;
  border-style: solid;

  @screen xl {
  padding: 0px 20px;
  border-left: 4px;
  border-color: #D36135;
  border-style: solid;
  }
  
  @screen md {
    padding: 0px 20px;
    border-left: 2px;
    border-color: #D36135;
    border-style: solid;
    }

  @media (max-width: var(--md)) {
    margin-top: 54px;
    margin-bottom: 54px;
    padding-left: 30px;
    padding-right: 0;
  }

  @media (max-width:var(--sm)) {
    margin-top: 54px;
    margin-bottom: 54px;
  }

  h2 {
    color: #0F3443;
    font-size: 40px;
    letter-spacing: 1px;
    line-height: 40px;
    font-weight: 400;
    font-family: poppins;
    margin-bottom: 10px;
  }
  p {
    color: #0F3443;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 28px;
    font-weight: 350;
    font-family: poppins;
    margin-bottom: 10px;
    text-align:justify ;
    

      @screen xs {
        font-size: 6px;
        line-height: 15px;
        font-weight: 250;
        
        }

        @screen md {
          font-size: 14px;
          line-height: 20px;
          font-weight: 300;
          
          }
    }
  

  h6 {
    color: #0F3443;
    font-size: 30px;
    letter-spacing: 1px;
    line-height: 40px;
    font-weight: 350;
    font-family: poppins;
    margin-bottom: 10px;
    text-align:justify ;
 
  }

  span {
    display: inline-block;
    font-family: var(--alt-font);
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 500;

  }
}

// // Blockquote style 03
.blockquote-style-03 {
  text-align: center;

  i {
    font-size: 50px;
    margin-bottom: 25px;
    display: inline-block;
  }

  h6 {
    font-family: var(--alt-font);
    margin-bottom: 25px;
    color: #232323;
    line-height: 34px;
    font-size: 20px;
    letter-spacing: normal;

    @media (min-width: var(--lg)) {
      font-size: 18px;
      line-height: 22px;
    }

  }

  span {
    font-family: var(--alt-font);
    letter-spacing: 4px;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    text-transform: uppercase;
  }
}

// // Blockquote style 04
.blockquote-style-04 {
  i {
    display: inline-block;
    font-size: 32px;
    margin-bottom: 30px;
    color: var(--base-color, #0038e3);
  }

  h2 {
    font-family: var(--alt-font);
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 25px;
    line-height: 3rem;
    color: #232323;
  }

  h3 {
    font-family: var(--alt-font);
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 25px;
    line-height: 3rem;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: #D36135;

    @screen md {
      font-size: 12px;
      letter-spacing: 1.2px;  
      
      }
    @screen xs {
      font-size: 10px;
      letter-spacing: 1.2px;
    
      
      }
  }
  h4 {
    font-family: var(--alt-font);
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 25px;
    line-height: 3rem;
    color: #232323;

    @screen xs {
      font-size: 20px;
      line-height:25px ;
      }

  }
  h5 {
    font-family: var(--alt-font);
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 25px;
    line-height: 3rem;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: #D36135; 
  
  }

    p {
      font-family: var(--alt-font);
      font-size: 1.1rem;
      font-weight: 300;
      margin-bottom: 25px;
      line-height: 2rem;
      color: #232323;
      text-align:justify ;

      @screen xs {
        font-size: 12px;
        line-height: 20px;
        
        }
    }

  h6 {
    font-family: var(--alt-font);
    font-size: 2.30rem;
    font-weight: 500;
    margin-bottom: 25px;
    line-height: 3rem;
    color: #232323;

    @media (max-width:var(--lg)) {
      margin-bottom: 15px;
    }
  }

  span {
    font-family: var(--alt-font);
  }
}

// // Blockquote style 04-01
.blockquote-style-04-01 {
  i {
    display: inline-block;
    font-size: 32px;
    margin-bottom: 30px;
    color: var(--base-color, #0038e3);
  }

  h2 {
    font-family: var(--alt-font);
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 25px;
    line-height: 3rem;
    color: #232323;
  }

  h3 {
    font-family: var(--alt-font);
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 25px;
    line-height: 3rem;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: #EDA71D;

    @screen md {
      font-size: 12px;
      letter-spacing: 1.2px;  
      
      }
    @screen xs {
      font-size: 10px;
      letter-spacing: 1.2px;
    
      
      }
  }
  h4 {
    font-family: var(--alt-font);
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 25px;
    line-height: 3rem;
    color: #232323;
    letter-spacing: 1px;

    @screen xs {
      font-size: 20px;
      line-height:25px ;
      }

  }
  h5 {
    font-family: var(--alt-font);
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 25px;
    line-height: 3rem;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: #EDA71D; 
  
  }

    p {
      font-family: var(--alt-font);
      font-size: 1.1rem;
      font-weight: 300;
      margin-bottom: 25px;
      line-height: 2rem;
      color: #232323;
      text-align:justify ;

      @screen xs {
        font-size: 12px;
        line-height: 20px;
        
        }
    }

  h6 {
    font-family: var(--alt-font);
    font-size: 2.30rem;
    font-weight: 500;
    margin-bottom: 25px;
    line-height: 3rem;
    color: #232323;

    @media (max-width:var(--lg)) {
      margin-bottom: 15px;
    }
  }

  span {
    font-family: var(--alt-font);
  }
}

// // Blockquote style 04-02
.blockquote-style-04-02 {
  i {
    display: inline-block;
    font-size: 32px;
    margin-bottom: 30px;
    color: var(--base-color, #0038e3);
  }

  h2 {
    font-family: var(--alt-font);
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 25px;
    line-height: 3rem;
    color: #232323;
  }

  h3 {
    font-family: var(--alt-font);
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 25px;
    line-height: 3rem;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: #D36135;

    @screen md {
      font-size: 12px;
      letter-spacing: 1.2px;  
      
      }
    @screen xs {
      font-size: 10px;
      letter-spacing: 1.2px;
    
      
      }
  }
  h4 {
    font-family: var(--alt-font);
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 25px;
    line-height: 3rem;
    color: #232323;
    letter-spacing:1px;

    @screen xs {
      font-size: 20px;
      line-height:25px ;
      }

  }
  h5 {
    font-family: var(--alt-font);
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 25px;
    line-height: 3rem;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: #326765; 
  
  }

    p {
      font-family: var(--alt-font);
      font-size: 1.1rem;
      font-weight: 300;
      margin-bottom: 25px;
      line-height: 2rem;
      color: #232323;
      text-align:justify ;

      @screen xs {
        font-size: 12px;
        line-height: 20px;
        
        }
    }

  h6 {
    font-family: var(--alt-font);
    font-size: 2.30rem;
    font-weight: 500;
    margin-bottom: 25px;
    line-height: 3rem;
    color: #232323;

    @media (max-width:var(--lg)) {
      margin-bottom: 15px;
    }
  }

  span {
    font-family: var(--alt-font);
  }
}

// // Blockquote style 04-03
.blockquote-style-04-03 {
  i {
    display: inline-block;
    font-size: 32px;
    margin-bottom: 30px;
    color: var(--base-color, #0038e3);
  }

  h2 {
    font-family: var(--alt-font);
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 25px;
    line-height: 3rem;
    color: #232323;
    letter-spacing:1px;
  
    @screen xs {
      font-size: 15px;
      letter-spacing: 1px;
      margin-bottom:10px;
  
      }
      @screen md {
        font-size: 20px;
        letter-spacing: 1px;
        margin-bottom:10px;
    
        }
        @screen lg {
          font-size: 22px;
          letter-spacing: 1px;
          margin-bottom:10px;
      
          }
  
  }

  h3 {
    font-family: var(--alt-font);
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 25px;
    line-height: 3rem;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: #D36135;

    @screen md {
      font-size: 12px;
      letter-spacing: 1.2px;  
      
      }
    @screen xs {
      font-size: 10px;
      letter-spacing: 1.2px;
    
      
      }
  }
  h4 {
    font-family: var(--alt-font);
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 25px;
    line-height: 3rem;
    color: #232323;
    letter-spacing:1px;

    @screen xs {
      font-size: 20px;
      line-height:25px ;
      }

  }
  h5 {
    font-family: var(--alt-font);
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 25px;
    line-height: 3rem;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: #6D597A; 
  
    @screen xs {
      font-size: 12px;
      line-height:15px;
      margin-bottom:10px;
      }
      @screen md {
        font-size: 12px;
        line-height:15px;
        margin-bottom:10px;
        }
        @screen lg {
          font-size: 12px;
          line-height:15px;
          margin-top:10px;
          }

  }

    p {
      font-family: var(--alt-font);
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 25px;
      line-height: 28px;
      color: #232323;
      text-align:justify ;

      @screen xs {
        font-size: 12px;
        line-height: 20px;
        
        }
        @screen md {
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 10px;
          
          }
          @screen lg {
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 10px;
            
            }
            @screen xl {
              font-size: 15px;
              line-height: 25px;
              margin-bottom: 10px;
              
              }
              
    }

  h6 {
    font-family: var(--alt-font);
    font-size: 2.30rem;
    font-weight: 500;
    margin-bottom: 25px;
    line-height: 3rem;
    color: #232323;

    @media (max-width:var(--lg)) {
      margin-bottom: 15px;
    }
  }

  span {
    font-family: var(--alt-font);
  }
}

// // Blockquote style 05
.blockquote-style-05 {
  i {
    display: inline-block;
    margin-bottom: 30px;
    font-size: 40px;
  }

  h6 {
    font-weight: 300;
    color: #fff;
    font-family: var(--alt-font);

    @media (max-width:var(--lg)) {
      margin-bottom: 15px;
    }
  }

  span {
    display: inline-block;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}